import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { db } from '../../Config/firebase.js';
import { deleteDoc, doc } from 'firebase/firestore';


import calibPDF from '../../Reports/Calib/calib.jsx';
// import relatPDF from '../../Reports/Relat/relat.jsx';
import DelDialog from '../Dialog/deldialog.jsx';
import { handleDelUser } from '../../Users/users.jsx';

import './lista.css';

export default function StickyHeadTable(props) {
  const columns = [...props.Header];
  const rows = [...props.List].reverse();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleDelCalib = async (id) => {
    try {
      await deleteDoc(doc(db, "caliblempe", id));
      props.Success(true);
    } catch (error) {
      console.error("Error deleting doc: ", error);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table aria-label="sticky table">
          <TableHead
            sx={{
              backgroundColor: 'primary.main',
            }}
          >

            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ maxWidth: column.maxWidth, minWidth: column.minWidth, fontWeight: 'bold', color: 'white' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  Nenhum item encontrado
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? (
                              column.format(value)
                            ) : column.id === 'opcU' ? (
                              <>
                                <Link to={`/users/${row.id}`}>
                                  <Tooltip title="Editar">
                                    <IconButton aria-label="edit" size="small">
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                                <DelDialog Confirm={() => handleDelUser(row.id)} />
                              </>
                            ) : column.id === 'opcC' ? (
                              <>
                                <Tooltip title="Calibração">
                                  <IconButton
                                    onClick={(e) => calibPDF(props.List, row.id)}
                                    aria-label="download"
                                    size="small"
                                  >
                                    <DownloadIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Relatório">
                                  <IconButton
                                    onClick={(e) => relatPDF(props.List, row.id)}
                                    aria-label="download"
                                    size="small"
                                  >
                                    <DownloadIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip> */}
                                {/* <Link to={`/ecalib/${row.id}`}>
                                  <Tooltip title="Editar">
                                    <IconButton aria-label="edit" size="small">
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Link> */}
                                <DelDialog Confirm={() => handleDelCalib(row.id)} />
                              </>
                            ) : column.id === 'calib' ? (
                              <IconButton
                                onClick={(e) => calibPDF(props.List, row.id)}
                                aria-label="download"
                                size="small"
                              >
                                <DownloadIcon fontSize="inherit" />
                              </IconButton>
                            // ) : column.id === 'relat' ? (
                            //   <IconButton
                            //     onClick={(e) => relatPDF(props.List, row.id)}
                            //     aria-label="download"
                            //     size="small"
                            //   >
                            //     <DownloadIcon fontSize="inherit" />
                            //   </IconButton>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelDisplayedRows={
          function defaultLabelDisplayedRows({ from, to, count }) { 
            return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`; 
          }	
        }
        rowsPerPageOptions={[10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          color: 'white',
          backgroundColor: 'primary.main',
          '& .MuiTablePagination-displayedRows': {
            marginBottom: '0px',
            fontWeight: 'bold',
          }
        }}
      />
    </Paper>
  );
}