import React, { useState, useContext} from 'react';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

import {AuthContext} from '../Context/auth.jsx';
import { useNavigate } from 'react-router-dom';

import { auth } from "../Config/firebase.js";
import { sendPasswordResetEmail, signInWithEmailAndPassword, setPersistence } from "firebase/auth"; 
import {  browserSessionPersistence, browserLocalPersistence } from "firebase/auth";

import Copyright from '../Components/Footer/copyright.jsx'

const StyledImage = styled('img')({});

export default function SignIn() {

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [mensagemErro, SetMensagemErro] = useState('');
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const {setLogado} = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDelete = () => {
    SetMensagemErro('');
    setMensagemSucesso('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    const data = new FormData(event.currentTarget);
    const emailInput = data.get('email');
    const passwordInput = data.get('password');

    try {
      // Determine the persistence type based on the checkbox state
      const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
      
      // Set the persistence
      await setPersistence(auth, persistenceType);
  
      // Attempt to sign in
      await signInWithEmailAndPassword(auth, emailInput, passwordInput);
      setLoading(true); // Start loading
      
      // Handle successful login
      setLogado(true);
      SetMensagemErro('');
      setMensagemSucesso('');
      navigate('/'); // Navigate to the home page
      
  
    } catch (error) {
      // Handle errors
      setLogado(false);
      SetMensagemErro('Usuário ou senha incorretos.');
      setMensagemSucesso('');
      console.error("Login error:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  function RecuperaUsuario(){
    sendPasswordResetEmail(auth, email)
    
      .then((Resultado) => {
        // Sucesso
        setMensagemSucesso('Se o e-mail estiver cadastrado, verifique sua caixa de entrada!');
        SetMensagemErro('');

      })
      .catch((erro) => {
        //erro
        SetMensagemErro('E-mail inválido, preencha o e-mail corretamente!');
        setMensagemSucesso('');
      });
  }

  return (
      <Container component="main" maxWidth="xs" sx={{justifyContent: 'center'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <StyledImage src="/Images/LOGO-lempe.png" alt='Lempe' height="80" sx={{ m: 6 }} />
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              onChange={(e)=>setEmail(e.target.value)}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <FormControl 
              margin="normal" 
              required 
              fullWidth 
              variant="outlined" 
            >
              <InputLabel htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  value="remember" 
                  color="primary" 
                />
              }
              label="Manter conectado"
            />
            <Box component="form" sx={{ mt: 1 }}>
              {mensagemSucesso && (
                <Chip 
                  label={mensagemSucesso} 
                  color="primary" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
              )}
              {mensagemErro && (
                <Chip 
                  label={mensagemErro} 
                  color="error" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
              )}
            </Box>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2 ,
                color: 'white'
              }}
            >
              Entrar
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="#" onClick={RecuperaUsuario} variant="body2">
                  Esqueceu sua senha?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  Não tem uma conta? Cadastre-se
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 12 }} />
      </Container>
  );
}