import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

import { auth, db } from "../Config/firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth"; 
import { doc, setDoc } from "firebase/firestore";

import { useNavigate } from 'react-router-dom';

import Copyright from '../Components/Footer/copyright.jsx'

const StyledImage = styled('img')({});

export default function SignUp() {

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [mensagemErro, SetMensagemErro] = useState('');
  const [mensagemErroC, SetMensagemErroC] = useState('');
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    SetMensagemErroC('');
    if(password !== event.target.value){
      SetMensagemErroC('Senhas não Conferem');
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => setShowCPassword((show) => !show);

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const handleDelete = () => {
    SetMensagemErro('');
    setMensagemSucesso('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const passwordInput = data.get('password');

    if (passwordInput === confirmPassword) {

    const nameInput = data.get('nome');
    const surnameInput = data.get('sobrenome');
    const emailInput = data.get('email');
    const passwordInput = data.get('password');

    setLoading(true); // Start loading

    try {
      // Attempt to sign in
      const userCredential = await createUserWithEmailAndPassword(auth, emailInput, passwordInput);
      
      // Handle successful login
      const user = userCredential.user;
      SetMensagemErro('');
      setMensagemSucesso('Usuário Cadastrado com Sucesso!');
      
      // Additional Firestore logic...
      const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, {
          displayName: nameInput,
          surname : surnameInput,
          email: emailInput,
          cliente: "Cadastrar",
          role: "Cliente",
        });

        navigate('/'); // Navigate to the home page
  
    } catch (error) {
      // Handle errors
      SetMensagemErro('Usuário ou senha incorretos.');
      setMensagemSucesso('');
      console.error("Login error:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  } else {

    SetMensagemErro('Preencha a Senha Corretamente!');
    setMensagemSucesso('');

  }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 60px)', // Min height of the viewport
      }}
    >
      <Container component="main" maxWidth="xs" sx={{justifyContent: 'center'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <StyledImage src="/Images/LOGO-lempe.png" alt='Lempe' height="80" sx={{ m: 6 }} />
          <Typography component="h1" variant="h5">
            Novo Usuário
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="nome"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="sobrenome"
              label="Sobrenome"
              name="sobrenome"
              autoComplete="sobrenome"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <FormControl 
              margin="normal" 
              required 
              fullWidth 
              variant="outlined" 
            >
              <InputLabel htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                onChange={(e)=>setPassword(e.target.value)}
                id="password"
                name="password"
                autoComplete="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Senha"
              />
            </FormControl>
            <FormControl 
              margin="normal" 
              required 
              fullWidth 
              variant="outlined" 
            >
              <InputLabel htmlFor="cpassword">Confirmar Senha</InputLabel>
              <OutlinedInput
                onChange={handleChangeConfirmPassword}
                id="cpassword"
                name="cpassword"
                autoComplete="cpassword"
                type={showCPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownCPassword}
                      edge="end"
                    >
                      {showCPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirmar Senha"
              />
            </FormControl>
            {mensagemErroC && (
                <Chip 
                  label={mensagemErroC} 
                  color="error" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
            )}
            <Box component="form" sx={{ mt: 1 }}>
              {mensagemSucesso && (
                <Chip 
                  label={mensagemSucesso} 
                  color="primary" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
              )}
              {mensagemErro && (
                <Chip 
                  label={mensagemErro} 
                  color="error" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
              )}
            </Box>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2 ,
                color: 'white'
              }}
            >
              Cadastrar
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Já tem uma conta? Entre
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 2,
          pb: 2,
          mt: 'auto', // Pushes the footer to the bottom
        }}
      >
        <Copyright />
      </Box>
    </Box>  
  );
}