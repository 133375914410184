import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import {AuthProvider} from './app/Context/auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ptBR } from '@mui/material/locale';

const theme = createTheme({
    palette: {
      primary: {
        main: '#00b3a1', // Example primary color
      },
      secondary: {
        main: '#52a3cc', // Example secondary color
      },
    },
    ptBR,
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <AuthProvider>
            <App/>
        </AuthProvider>
    </ThemeProvider>
);