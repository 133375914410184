import React, { useContext } from 'react';
import { BrowserRouter , Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './app/Context/auth.jsx';

import NovaCalib from './app/NovaCalib/novacalib.jsx';
import EditCalib from './app/EditCalib/editcalib.jsx';
import LCalib from './app/Calib/calib.jsx';
import SignIn from './app/SignIn/signin.jsx'
import SignUp from './app/SignUp/signup.jsx';
import Navbar from './app/Components/Navbar/navbar.jsx';
import EditConta from './app/EditConta/editconta.jsx';
import Users from './app/Users/users.jsx';

function App(){
  const { logado, role } = useContext(AuthContext);

  function SecureRoute({ children, allowedRoles }) {

    if (!logado) {
      return <Navigate to="/signin" />;
    }
  
    // Check if the user's role is in the list of allowed roles for this route
    if (!allowedRoles.includes(role)) {
      return <Navigate to="/" />; // Or any other 'unauthorized access' page
    }
  
    return children;
  }

  return <BrowserRouter>
    <Routes>

      <Route element={<SignIn />} path='/signin' exact/>
      <Route element={<SignUp />} path='/signup' exact/>

      <Route element={<SecureRoute allowedRoles={['Admin', 'Cliente']}><Navbar /><LCalib /></SecureRoute>} path='/' exact/>
      <Route element={<SecureRoute allowedRoles={['Admin', 'Cliente']}><Navbar /><EditConta /></SecureRoute>} path='/econta/:id' exact/>


      {/* Routes accessible only by 'admin' role */}
      <Route element={<SecureRoute allowedRoles={['Admin']}><Navbar /><Users /></SecureRoute>} path='/users' exact/>
      <Route element={<SecureRoute allowedRoles={['Admin']}><Navbar /><EditConta /></SecureRoute>} path='/users/:id' exact/>
      <Route element={<SecureRoute allowedRoles={['Admin']}><Navbar /><NovaCalib /></SecureRoute>} path='/ncalib' exact/>
      <Route element={<SecureRoute allowedRoles={['Admin']}><Navbar /><EditCalib /></SecureRoute>} path='/ecalib/:id' exact/>

    </Routes>
  </BrowserRouter>;
}

export default App;