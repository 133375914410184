import React, { useEffect, useState, createContext } from 'react';
import { auth, db } from '../Config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const AuthContext = createContext({});

function AuthProvider(props){

    const [logado, setLogado] = useState(null); // null initially to represent loading/auth check state
    const [role, setRole] = useState(null); // Add state for role
    const [name, setName] = useState(null); // Add state for role

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                getDoc(userRef).then(docSnap => {
                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setRole(userData.role); // Set the role from Firestore
                        setName(userData.displayName); // Set the role from Firestore
                        setLogado(true);
                    } else {
                        // Handle the case where user data doesn't exist
                        setLogado(false);
                        setRole(null);
                    }
                });
            } else {
                // No user is logged in
                setLogado(false);
                setRole(null);
            }
        });

        return () => unsubscribe();
    }, []);
    
    // If logado is null, it means we're still checking the auth state
    if (logado === null) {
        return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh' // Full viewport height
              }}
            >
              <CircularProgress />
            </Box>
          );
    }

    return (
        <AuthContext.Provider value={{logado, setLogado, role, name}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };