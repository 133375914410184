import React, { useContext } from 'react';
import {AuthContext} from '../../Context/auth';
import { auth } from '../../Config/firebase';
import { Link } from 'react-router-dom';


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

function Navbar(){

  const StyledImage = styled('img')({});
  const {setLogado} = useContext(AuthContext);
  const settings = [/* 'Conta', */ 'Sair'];
  const { role } = useContext(AuthContext);
  const { name } = useContext(AuthContext);

  const pages = role === 'Admin'
  ? [
      { title: 'Calibrações', path: '/' },
      { title: 'Usuários', path: '/users' },
      // { title: 'Nova Calibração', path: '/ncalib' },
    ]
  : role === 'Cliente'
  ? [
      { title: 'Calibrações', path: '/' }
    ]
  : [];

  const Logout = async () => {
    try {
      await auth.signOut();
      setLogado(false);
    } catch (error) {
      console.error("Error signing out: ", error);
      // Optionally, handle any logout errors here
    }
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
    if (setting === 'Sair') {
        Logout(); // Call your Logout function if the setting is 'Logout'
    }
  };

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        backgroundImage: 'linear-gradient(175deg, #7de2d8, #00b3a1)',
        height: '60px',
      }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <StyledImage src="/Images/LOGO-lempe.png" alt='Lempe' height="40" sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }} />
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu('')}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={() => setAnchorElNav(null)}>
                  <Link to={page.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography textAlign='center'>{page.title}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center' }}>
            <StyledImage src="/Images/LOGO-lempe.png" alt='Lempe' height="40" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link 
                key={page.title}
                to={page.path}
                style={{ textDecoration: 'none' }}
              >
                <Button
                    onClick={() => setAnchorElNav(null)}
                    sx={{
                    mr: 2,
                    color: 'white',
                    display: 'block',
                    fontWeight: 700,
                    '&:hover': {
                        backgroundColor: '#00b3a1', // Replace with your desired hover color
                    },
                    }}
                >
                  {page.title}
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Opções do Usuário">
              <IconButton 
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenUserMenu}
                sx={{ 
                  '&:hover': {
                    backgroundColor: '#00b3a1', // Replace with your desired hover color
                  },
                }}
                >
                <AccountCircle 
                  sx={{ 
                    color : 'white', 
                    height : 38, 
                    width : 38, 
                  }} 
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu('')}
            >
              <Typography textAlign="center" px={2} py={1}>  Olá, {name}!</Typography>
              <Divider variant="middle"/>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)} >
                  {setting === 'Conta' ? (
                    <Link to={`/econta/${auth.currentUser.uid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography textAlign="center">{setting}</Typography>
                    </Link>
                  ) : (
                    <Typography textAlign="center">{setting}</Typography>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );

  }

export default Navbar;