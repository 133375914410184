import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { db } from "../Config/firebase.js";
import { getDoc, doc, updateDoc } from "firebase/firestore";

import Copyright from '../Components/Footer/copyright.jsx'

export default function EditConta() {

  const [role, setRole] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [CNPJ, setCNPJ] = useState('');
  const [showClientField, setShowClientField] = useState(false);
  const [showAdminField, setShowAdminField] = useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
    setShowClientField(event.target.value === 'Cliente');
    setShowAdminField(event.target.value === 'Admin');
  };

  const handleChangeN = (event) => {
    setNome(event.target.value);
  };

  const handleChangeS = (event) => {
    setSobrenome(event.target.value);
  };

  const handleChangeC = (event) => {
    setCNPJ(event.target.value);
  };

  

  const { id } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [mensagemErro, SetMensagemErro] = useState('');
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocumentData(docSnap.data());
          setRole(docSnap.data().role);
          setNome(docSnap.data().displayName);
          setSobrenome(docSnap.data().surname);
          setCNPJ(docSnap.data().cliente);
          setShowClientField(docSnap.data().role === 'Cliente');
          setShowAdminField(docSnap.data().role === 'Admin');
          console.log(docSnap.data().cliente);
        } else {
          console.log("Documento não encontrado!");
        }
      } catch (err) {
        console.error("Erro ao procurar documento: ", err);
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [id]);

  const handleDelete = () => {
    SetMensagemErro('');
    setMensagemSucesso('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const clienteInput = data.get('cliente');
    const adminInput = data.get('cAdmin');

    if (clienteInput || adminInput === "Administrador") {

    const nameInput = data.get('nome');
    const roleInput = data.get('role');
    const clienteInput = data.get('cliente');
    


    setLoading(true); // Start loading

    try {
      SetMensagemErro('');
      setMensagemSucesso('Usuário Atualizado com Sucesso!');

      // Additional Firestore logic...
      const userDocRef = doc(db, 'users', id);
        await updateDoc(userDocRef, {
          displayName: nameInput,
          cliente: clienteInput,
          role: roleInput,
        });
    } catch (error) {
      // Handle errors
      SetMensagemErro('Erro ao atualizar usuário.');
      setMensagemSucesso('');
      console.error("Login error:", error);
    } finally {
      setLoading(false); // Stop loading
      setMensagemSucesso('Usuário Atualizado com Sucesso!');
      SetMensagemErro('');
    }
  } else {
    
    SetMensagemErro('Preencha o CNPJ Corretamente!');
    setMensagemSucesso('');

  }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 60px)', // Min height of the viewport
      }}
    >
      <Container component="main" maxWidth="xs" sx={{justifyContent: 'center'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h4" m={6}>
            Editar {documentData ? documentData.displayName : 'Loading...'}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="nome"
              autoFocus
              value={nome}
              onChange={handleChangeN}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="sobrenome"
              label="Sobrenome"
              name="sobrenome"
              autoComplete="sobrenome"
              autoFocus
              value={sobrenome}
              onChange={handleChangeS}
            />
            <FormControl 
              margin="normal" 
              required 
              fullWidth 
              variant="outlined" 
            >
              <InputLabel id="role">Tipo de Conta</InputLabel>
              <Select
                name="role"
                labelId="role"
                id="role"
                value={role}
                label="Tipo de Conta"
                onChange={handleChange}
              >
                <MenuItem value={"Cliente"}>Cliente</MenuItem>
                <MenuItem value={"Admin"}>Administrador</MenuItem>
              </Select>
            </FormControl>
            {showClientField && (
              <TextField
                margin="normal"
                required
                fullWidth
                id="cliente"
                label="CNPJ"
                name="cliente"
                autoComplete="cliente"
                autoFocus
                value={CNPJ}
                onChange={handleChangeC}
              />
            )}
            {showAdminField && (
              <TextField
                margin="normal"
                required
                fullWidth
                id="cAdmin "
                label="Confirme Tipo de Conta"
                name="cAdmin"
                autoComplete="cAdmin"
                autoFocus
              />
            )}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              {mensagemSucesso && (
                <Chip 
                  label={mensagemSucesso} 
                  color="primary" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
              )}
              {mensagemErro && (
                <Chip 
                  label={mensagemErro} 
                  color="error" 
                  variant="outlined" 
                  onDelete={handleDelete} 
                />
              )}
            </Box>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2 ,
                color: 'white'
              }}
            >
              Salvar
            </LoadingButton>
          </Box>
        </Box>
      </Container>
      <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2,
            mt: 'auto', // Pushes the footer to the bottom
          }}
        >
          <Copyright />
        </Box>
    </Box>  
  );
}