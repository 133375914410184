import React, {useState, useEffect, useContext} from 'react';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CancelIcon from '@mui/icons-material/Cancel';

import StickyHeadTable from '../Components/Lista/lista.jsx';
import Copyright from '../Components/Footer/copyright.jsx';

import { db } from '../Config/firebase';
import { collection, query, getDocs, doc, deleteDoc } from "firebase/firestore";
import { AuthContext } from '../Context/auth.jsx';

import './users.css';

export const handleDelUser = async (id) => {
  try {
    await deleteDoc(doc(db, "users", id));
  } catch (error) {
    console.error("Error deleting doc: ", error);
    // Optionally, handle any logout errors here
  }
}

function Users(){

  const [busca, setBusca] = useState('');
  const [busca1, setBusca1] = useState('');
  const [texto, setTexto] = useState('');
  const [list, setList] = useState([]);
  const { role } = useContext(AuthContext);
  const [isSnSearch, setIsSnSearch] = useState(false);

  const header = [
    { id: 'displayName', label: 'Nome do Usuário', minWidth: 90 },
    { id: 'email', label: 'E-mail', minWidth: 90 },
    { id: 'cliente', label: 'Empresa', minWidth: 90 },
    { id: 'role', label: 'Tipo de Conta', minWidth: 90 },
    { id: 'opcU', label: 'Opções', minWidth: 90 },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check the value of isSnSearch to determine which function to call
    if (isSnSearch) {
      // If isSnSearch is true, it means we are searching by SN
      setBusca1(texto);
      setBusca('');
    } else {
      // If isSnSearch is false, it means we are searching by OS
      setBusca(texto);
      setBusca1('');
    }
  };
  
  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {

      const querySnapshot = await getDocs(query(collection(db, "users")));
      let lista = querySnapshot.docs
      .filter(doc => doc.data().displayName.indexOf(busca) >= 0 && doc.data().email.indexOf(busca1) >= 0)
        .map(doc => ({
          id: doc.id,
          cliente: doc.data().cliente,
          displayName: doc.data().displayName,
          email: doc.data().email,
          role: doc.data().role,
      }));

      setList(lista);
    };

    fetchData().catch(console.error);
  }, [busca, busca1, role]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 60px)', // Min height of the viewport
      }}
    >
      
      {/* Header */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 2,
          pb: 2,
        }}
      >
        <Container>
          <Stack
            sx={{ 
              pt: 2,
              flexDirection: {
                xs: 'column', // Column layout for extra-small screens
                sm: 'column', // Column layout for extra-small screens
                md: 'row', // Row layout for small screens and above
              },
              alignItems: { 
                xs: 'center', 
                sm: 'center', 
                md: 'flex-start' 
              }, // Center alignment for xs screens
              justifyContent: "center",
              gap: { 
                xs: 2, 
                sm: 2, 
                md: 20 
              }, // Adjust spacing based on screen size
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="primary"
              sx={{
                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', // Optional text shadow for depth
                mt: 1, // Reduced top margin
                mb: 2, // Slightly increased bottom margin for spacing
                letterSpacing: '0.05rem', // Reduced letter spacing
                // Other styling adjustments can be made here
              }}
            >
              Usuários
            </Typography>

            {/* Search */}
            <Paper
              component="form"
              sx={{ p: '4px 4px', display: 'flex', alignItems: 'center', width: 400, my: 'auto' }}
              onSubmit={handleSubmit}
            >
              <InputBase
                onChange={(e)=>setTexto(e.target.value)}
                name='search'
                sx={{ ml: 1, flex: 1 }}
                placeholder="Pesquisar"
                value={texto}
                inputProps={{ 'aria-label': 'search the reports' }}
                endAdornment={
                  texto && (
                    <IconButton
                      size='small'
                      onClick={() => {
                        if(busca === '' && busca1 === ''){
                          setTexto('');
                        } else {
                          setTexto('');
                          setBusca('');
                          setBusca1('');
                        }
                      }}
                      aria-label="clear"
                    >
                      <CancelIcon fontSize='small'/>
                    </IconButton>
                  )
                }
              />
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>nome</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      name='isSn'
                      checked={isSnSearch}
                      onChange={(e) => {
                        setIsSnSearch(e.target.checked);
                        if (texto !== '') {
                          if (e.target.checked) {
                            // If isSnSearch will be true, it means we are searching by SN
                            setBusca1(texto);
                            setBusca('');
                          } else {
                            // If isSnSearch will be false, it means we are searching by OS
                            setBusca(texto);
                            setBusca1('');
                          }
                        }
                      }}
                      color="default"
                      sx={{
                        '& .MuiSwitch-thumb': {
                          backgroundColor: 'primary.main'
                        },
                      }}
                    />
                  }
                />
                <Typography>e-mail</Typography>
              </Stack>
            </Paper>
            {/* Search */}

          </Stack>
        </Container>
      </Box>
      {/* Header */}

      {/* Table */}
      <Box 
          sx={{
            mt: 2,
            mb: 1,
          }}
        >
          <Container maxWidth="xl"><StickyHeadTable List={list} Header={header} /></Container> 
      </Box>
      {/* Table */}

      {/* Footer */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 2,
          pb: 2,
          mt: 'auto', // Pushes the footer to the bottom
        }}
      >
        <Copyright />
      </Box>
      {/* End footer */}
      
    </Box>
  );
  }

export default Users;

/* import React from 'react';

function Users(){
    return <div>
      <h1>Em Construção</h1>
    </div>
  }

export default Users; */